import { isRouteErrorResponse, useRouteError } from "@remix-run/react";
import type { FC, PropsWithChildren } from "react";

import { TypeUtil } from "@wind/util";

import { useCurrentPath } from "../../hooks/useCurrentPath.js";
import Button from "../Button/Button.js";
import JsonViewer from "../DataViewer/JsonViewer.js";
import Header from "../Typography/Header.js";
import Paragraph from "../Typography/Paragraph.js";
import CenteredCard from "./CenteredCard.js";

declare global {
  interface Window {
    ENV?: any;
  }
}

const defaultStatusText: Record<string, string> = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  500: "Internal Server Error",
};

const useHomePage = () => {
  const path = useCurrentPath();
  return path.startsWith("/s/") ? `/s/${path.split("/")[2]}` : "/s";
};

const ErrorBoundaryCard: FC<PropsWithChildren> = ({ children }) => {
  const error = useRouteError();
  const homePage = useHomePage();

  // when true, this is what used to go to `CatchBoundary`
  if (isRouteErrorResponse(error)) {
    let statusText = error.statusText;

    if (!statusText || statusText === "") {
      statusText = defaultStatusText[error.status] ?? "Unknown Error";
    }

    return (
      <CenteredCard>
        <Header>
          {statusText} - {error.status}
        </Header>
        {TypeUtil.isObject(error.data) || TypeUtil.isArray(error.data) ? (
          <JsonViewer json={error.data} />
        ) : error.data ? (
          <Paragraph>{error.data}</Paragraph>
        ) : null}
        {children}
        <Button to={homePage}>Go Home</Button>
      </CenteredCard>
    );
  }

  let errorMessage = "Unknown error";
  if (error instanceof Error) {
    if ("message" in error) {
      errorMessage = error.message;
    }
  }

  return (
    <CenteredCard>
      <Header>Uh oh ...</Header>
      <p>Something went wrong.</p>
      <div className="break-all font-mono text-xs">{errorMessage}</div>
    </CenteredCard>
  );
};

export default ErrorBoundaryCard;
